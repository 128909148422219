<template>
  <v-dialog v-model="openDatosNovedades" persistent max-width="800px">
    <v-card>
      <v-app-bar color="blue darken-1" dark>
        <v-toolbar-title
          >Escuela: {{ dataEscuela.escuela }}/{{
            dataEscuela.grupo_operativo
          }}/{{ dataEscuela.departamental }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <div>
          <v-btn text @click="$emit('CerrarDataNovedades', false)"
            >Cerrar</v-btn
          >
        </div>
      </v-app-bar>
      <v-card class="pa-4">
        <v-data-table
          :headers="headers"
          :items="datosNovedades"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogNovedades",
  props: {
    openDatosNovedades: {
      type: Boolean,
      default: false,
    },
    dataEscuela: {
      type: Object,
      default: () => ({}),
    },
    datosNovedades: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Jerarquia",
          align: "start",
          sortable: false,
          value: "policium.jerarquia",
        },
        { text: "Nombre", value: "policium.nombre" },
        { text: "Novedad", value: "novedad" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
