<template>
  <div>
    <TitleDashboard
      :breadcrumbsItems="items"
      :title="'Panel Monitoreo de  Escuelas'"
    />

    <v-row class="mt-4">
      <v-col cols="12" md="4" v-if="userData.dni == '38216729'">
        <CardDeshabilitarPorsentajeVue />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="selectedColumn"
                  :items="filterOptions"
                  item-text="label"
                  item-value="column"
                  label="Filtrar por"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" v-if="selectedColumn">
                <v-select
                  v-model="selectedValue"
                  :items="selectedColumnOptions"
                  label="Seleccionar Valor"
                ></v-select>
              </v-col>
              <v-col
                v-if="selectedColumn || selectedValue"
                cols="12"
                md="1"
                class="d-flex justify-center align-center"
              >
                <v-btn fab dark small color="red" @click="cleanFilter()">
                  <v-icon dark> mdi-filter-off </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filteredTableData"
            :search="search"
          >
            <template v-slot:[`item.habilitada`]="slotProps">
              <v-chip
                class="ma-2"
                :color="slotProps.item.habilitada == true ? 'green' : 'red'"
                outlined
              >
                {{
                  slotProps.item.habilitada == true
                    ? "Hablilitada"
                    : "suspendida"
                }}
              </v-chip>
            </template>
            <template v-slot:[`item.porcentaje_votos`]="slotProps">
              {{ slotProps.item.porcentaje_votos + "%" }}
            </template>
            <template v-slot:[`item.fecha_actualizacion`]="slotProps">
              <v-chip class="ma-0" outlined>
                {{ parseDate(slotProps.item.fecha_actualizacion) }}
              </v-chip>
            </template>
            <template v-slot:[`item.acciones`]="slotProps">
              <v-btn
                color="green"
                elevation="2"
                class="ml-2"
                icon
                outlined
                text
                @click="mostrarDataPolicias(slotProps.item)"
                ><v-icon>mdi-file-eye</v-icon></v-btn
              >
              <v-btn
                color="blue"
                elevation="2"
                class="ml-2"
                icon
                outlined
                text
                @click="mostrarDataNovedades(slotProps.item)"
                ><v-icon>mdi-alpha-n-box-outline</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <DialogPoliciasPresentes
      :dataEscuela="dataTotalEscuela"
      :openDatosPolicias="openDialogDataPolice"
      :datosPolicias="dataPoliciaEscuela"
      :datosPoliciasCitados="policiasCitado"
      @CerrarDataPolicia="(item) => (this.openDialogDataPolice = item)"
    />
    <DialogNovedadesVue
      :dataEscuela="dataTotalEscuela"
      :openDatosNovedades="openDialogDataNovedades"
      :datosNovedades="dataNovedades"
      @CerrarDataNovedades="(item) => (this.openDialogDataNovedades = item)"
    />
  </div>
</template>

<script>
import moment from "moment";

import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import DialogPoliciasPresentes from "@/components/Cards/DialogPoliciasPresentes.vue";
import DialogNovedadesVue from "@/components/Cards/DialogNovedades.vue";
import io from "socket.io-client";
const socket = io(process.env.VUE_APP_BACKEND_ENVIRONMENT_SOCKET);
import axios from "axios";
import CardDeshabilitarPorsentajeVue from "@/components/Cards/CardDeshabilitarPorsentaje.vue";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    DialogPoliciasPresentes,
    DialogNovedadesVue,
    CardDeshabilitarPorsentajeVue,
  },
  beforeCreate() {
    socket.on("actualizarTabla", (data) => {
      console.log(data);
      this.updateObject(data);
    });
  },
  async created() {
    // Obtener los datos iniciales
    this.userData = JSON.parse(localStorage.getItem("userData"));
    console.log(this.userData);
    await this.updateData();
  },
  data() {
    return {
      selectedValue: null,
      filterOptions: [
        { label: "Departamentales", column: "departamental" },
        { label: "Dependencias", column: "dependencia" },
        { label: "Departamento", column: "departamento" },
        { label: "Grupo Operativo", column: "grupo_operativo" },
        // Agrega más opciones aquí para otras columnas
      ],
      dataTotalEscuela: {},
      userData: null,
      dataNovedades: [],
      policiasCitado: [],
      dataPoliciaEscuela: [],
      openDialogDataPolice: false,
      openDialogDataNovedades: false,
      dataPolicias: [],
      escuelasconPolcias: [],
      restoPorcentajeTotal: null,
      porcentajePromedioTotalDepartamentos: null,
      titulosDepartamentos: [],
      datosDepartamentos: [],
      titulosDepartamentales: [],
      datosDepartamentales: [],
      tableData: [],
      search: "",
      selectedColumn: null,

      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Panel Escuelas",
          disabled: false,
          link: false,
        },
      ],
    };
  },
  computed: {
    table() {
      return this.tableData;
    },
    headers() {
      return [
        {
          sortable: true,
          text: "Escuela",
          value: "escuela",
        },
        {
          text: "Dependencia",
          value: "dependencia",
        },
        {
          text: "Departamento",
          value: "departamento",
        },
        {
          text: "Departamental",
          value: "departamental",
        },
        {
          text: "Grupo",
          value: "grupo_operativo",
        },
        {
          text: "Urnas",
          value: "urnas",
        },
        {
          text: "Estado",
          value: "habilitada",
        },
        {
          text: "Porcentaje Votos",
          value: "porcentaje_votos",
        },
        {
          text: "Policias Presentes",
          value: "cantidad_policias",
        },
        {
          text: "Ult. Acc.",
          value: "fecha_actualizacion",
        },
        {
          text: "Acciones",
          value: "acciones",
        },
      ];
    },

    selectedColumnOptions() {
      if (!this.selectedColumn) {
        return [];
      }

      // Filtra la tabla para obtener los valores únicos de la columna seleccionada
      const uniqueValues = Array.from(
        new Set(this.tableData.map((item) => item[this.selectedColumn]))
      );
      console.log(uniqueValues);
      return uniqueValues;
    },
    filteredTableData() {
      if (!this.selectedValue) return this.tableData;
      const val = this.selectedColumn;
      return this.tableData.filter((elem) => elem[val] === this.selectedValue);
    },
  },
  methods: {
    parseDate(date) {
      if (date) {
        return moment(String(date)).format("DD-MM-YY | HH:mm");
      }
    },
    cleanFilter() {
      this.selectedColumn = null;
      this.selectedValue = null;
    },
    async mostrarDataNovedades(escuela) {
      this.dataTotalEscuela = escuela;
      let data = escuela.id_escuela;
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT_ELECCIONES}/Escuelas/novedades/escuela/${data}`
        );
        this.openDialogDataNovedades = true;
        this.dataNovedades = response.data.novedades;
        console.log(
          "esto es la variable con las novedades",
          this.dataNovedades
        );
      } catch (err) {
        this.openDialogDataNovedades = true;
        console.log("este es el error", err);
      }
    },
    async mostrarDataPolicias(escuela) {
      this.dataTotalEscuela = escuela;

      let data = escuela.id_escuela;
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT_ELECCIONES}/Escuelas/escuelas/${data}`
        );
        this.openDialogDataPolice = true;
        console.log(response.data.escuela[0]);
        this.dataPoliciaEscuela = response.data.escuela[0].Asistencia;
        this.policiasCitado = response.data.escuela[0].citados;
      } catch (err) {
        console.log(err);
      }
    },

    async updateData() {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT_ELECCIONES}/Estadisticas/vista-escuelas`
        )
        .then((response) => {
          console.log(">>>>>> data de la tabla", response);
          this.tableData = response.data.resultados;
          console.log(">>>>>> data de la tabla", this.tableData);
        });
    },
    async updateObject(newObject) {
      console.log("new object", newObject);
      const indiceObjeto = this.tableData.findIndex(
        (objeto) => objeto.id_escuela === newObject.id_escuela
      );

      this.$set(this.tableData, indiceObjeto, newObject);
      console.log("indice", indiceObjeto);
      this.tableData[indiceObjeto] = newObject;

      let tabla = this.tableData;
      this.tableData = tabla;
    },
  },
};
</script>

<style></style>
