var VueCli;
var axios;
var store;

const d6 = {
  get: (options) =>
    new Promise(async (resolve, reject) => {
      try {
        let data = options.params || {};
        let response = await axios.get(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT_ELECCIONES}${options.url}`,
          { params: data }
        );
        return resolve(response);
      } catch (e) {
        console.log(e);
        reject();
        return d6.processResponse(e, options);
      }
    }),
  post: (options) =>
    new Promise(async (resolve, reject) => {
      try {
        options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`;
        let response = await axios.post(options.url, options.formData);

        let result = await d6.processResponse(response, options);
        return resolve(result);
      } catch (e) {
        reject(e);
        return d6.processResponse(e, options);
      }
    }),
  put: (options) =>
    new Promise(async (resolve, reject) => {
      try {
        console.log("desde el put", options.formData);
        options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}/${options.id}`;
        let response = await axios.put(options.url, options.formData);
        let result = await d6.processResponse(response, options);
        return resolve(result);
      } catch (e) {
        return d6.processResponse(e, options);
      }
    }),
  delete: (options) =>
    new Promise(async (resolve, reject) => {
      console.log("desde ultils", options.data);
      try {
        options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}/${options.data}`;
        let response = await axios.delete(options.url);
        let result = await d6.processResponse(response, options);
        return resolve(result);
      } catch (e) {
        return d6.processResponse(e, options);
      }
    }),
  processResponse: (response, options) =>
    new Promise(async (resolve, reject) => {
      let errAlert = () => {
        let msg =
          typeof response == "object"
            ? JSON.stringify(response.data)
            : response.data;
        alert(msg);
      };
      if (!response) console.error("response: ", response, options);
      if (response.status == 200 || response.status == 201) {
        let successMsg =
          response.data.title || "La carga se realizo con exito.";
        VueCli.$toast.open({
          message: successMsg,
          position: "top-right",
          type: "success",
          duration: 3000,
        });
        return resolve(response);
      } else {
        VueCli.$toast.open({
          message: response.response.data.msj || "error",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
        return reject(response);
      }
    }),
};

module.exports = (Store, Vue, Axios) => {
  VueCli = Vue;
  axios = Axios;
  store = Store;
  return {
    get: d6.get,
    post: d6.post,
    put: d6.put,
    delete: d6.delete,
  };
};
