<template>
  <v-dialog v-model="openDatosPolicias" persistent max-width="800px">
    <v-card>
      <v-app-bar color="blue darken-1" dark>
        <v-toolbar-title
          >Escuela: {{ dataEscuela.escuela }}/{{
            dataEscuela.grupo_operativo
          }}/{{ dataEscuela.departamental }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <div>
          <v-btn text @click="$emit('CerrarDataPolicia', false)">Cerrar</v-btn>
        </div>
      </v-app-bar>
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-subtitle> Policias Presentes </v-card-subtitle>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="datosPolicias"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.entrada`]="{ item }">
                    <v-chip class="ma-0" outlined>
                      {{ parseDate(item.entrada) }}
                    </v-chip>
                  </template></v-data-table
                >
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-subtitle> Policias Citados </v-card-subtitle>
              <v-card-text>
                <v-data-table
                  :headers="headersPolciasCitados"
                  :items="datosPoliciasCitados"
                  :items-per-page="5"
                  class="elevation-1"
                ></v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "DialogPoliciasPresentes",
  props: {
    dataEscuela: {
      type: Object,
      default: () => ({}),
    },
    openDatosPolicias: {
      type: Boolean,
      default: false,
    },
    datosPolicias: {
      type: Array,
      default: [],
    },
    datosPoliciasCitados: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Jerarquia",
          align: "start",
          sortable: false,
          value: "policium.jerarquia",
        },
        { text: "Nombre", value: "policium.nombre" },
        { text: "DNI", value: "policium.dni" },
        { text: "Destino", value: "policium.destino" },
        { text: "Telefono", value: "policium.telefono" },
        { text: "Fecha de Ingreso", value: "entrada" },
      ],
      headersPolciasCitados: [
        {
          text: "Jerarquia",
          align: "start",
          sortable: false,
          value: "policium.jerarquia",
        },
        { text: "Nombre", value: "policium.nombre" },
        { text: "DNI", value: "policium.dni" },
        { text: "Destino", value: "policium.destino" },
        { text: "Telefono", value: "policium.telefono" },
      ],
    };
  },
  methods: {
    parseDate(date) {
      if (date) {
        return moment(String(date)).format("DD-MM-YY | HH:mm");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
