import Vue from "vue";
import axios from "axios";
import Cookies from "js-cookie";
import router from "@/router";

const state = () => ({
  logged: false,
  userData: {},
  token: null,
});

const mutations = {
  setUserData(state, payload) {
    state.userData = payload;
  },
  setLogin(state, status) {
    state.logged = status;
  },
  setToken(state, token) {
    state.token = token; // Actualiza la variable de estado con el token recibido
    Cookies.set("token", token, { secure: true, sameSite: "Strict", expires: 7 }); // Guarda el token en una cookie segura
  },
  clearToken(state) {
    state.token = null;
    Cookies.remove("token");
  },
  setUserData(state, payload) {
    state.userData = payload;
    localStorage.setItem('userData', JSON.stringify(payload));
  },
};

const actions = {
  async login({ commit }, payload) {
    try {
      let request = await axios.post(
        `${process.env.VUE_APP_BACKEND_ENVIRONMENT_ELECCIONES}/usuarios/auth/signin`,
        payload
      );
      console.log(request);
      
      const token = request.data.accessToken;
       
      commit("setToken", token); // Llama a la mutación para establecer el token en el estado y la cookie
      commit("setUserData", request.data.policia); // Llama a la mutación para establecer los datos del usuario y el storage
      commit("setLogin", true);
      if (router.currentRoute.name != "/")router.push({ path: "/" }); // Redirige al usuario a la ruta raíz
      
      return request.data;
    } catch (error) {
      
      Vue.prototype.$toast.open({
        message: "Usuario o contreseña incorrecto",
        position: "top-right",
        type: "error",
        duration: 3000,
      });

      throw error;
    }
  },

  checkLogin({ commit }) {
    const token = Cookies.get("token");
    commit("setLogin", !!token);
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      commit("setUserData", userData);
    }
  },
  logout({ commit }) {
    commit("clearToken");
    commit("setUserData", {});
    commit("setLogin", false);
    localStorage.removeItem('userData');
  },
};

const getters = {
  alumnos(state) {
    return state.alumnos;
  },
  usuarios(state) {
    return state.usuarios;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
