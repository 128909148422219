import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Entradas from '../views/EntradasView.vue'
import Salidas from '../views/SalidasView.vue'
import Novedades from '../views/NovedadesView.vue'
import Escuela from '../views/EscuelaView.vue'
import Alumnos from '../views/AlumnosView.vue'
import Asistencias from '../views/AsistenciasView.vue'
import Graficas from '../views/GraficasView.vue'
import PanelInformativo from '../views/PanelInformativo.vue'
import EdicionPorEscuela from '../views/EdicionPorEscuela.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/entradas',
    name: 'entradas',
    component: Entradas
  },
  {
    path: '/salidas',
    name: 'salidas',
    component: Salidas
  },
  {
    path: '/novedades',
    name: 'novedades',
    component: Novedades
  },
  {
    path: '/escuela',
    name: 'escuela',
    component: Escuela
  },
  {
    path: '/alumnos',
    name: 'listAlumnos',
    component: Alumnos
  },
  {
    path: '/asistencias',
    name: 'asistencias',
    component: Asistencias
  },
  {
    path: '/graficas',
    name: 'graficas',
    component: Graficas
  },
  {
    path: '/panelInformativo',
    name: 'panelInformativo',
    component: PanelInformativo
  },
  {
    path: '/edicionEscuela',
    name: 'edicionEscuela',
    component: EdicionPorEscuela
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/alumno/:id',
    name: 'alumno',
    component: () => import(/* webpackChunkName: "about" */ '../views/parametros/add-edit/alumno.vue')
  },
  {
    path: '/usuario/:id',
    name: 'usuario',
    component: () => import(/* webpackChunkName: "about" */ '../views/parametros/add-edit/usuario.vue')
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),

    
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
