<template>
  <div>
    <TitleDashboard
      :breadcrumbsItems="items"
      :title="'Panel de Estadisticas Generales'"
    />

    <v-row class="mt-4">
      <v-col cols="12" md="6" >
        <v-card elevation="0" min-height="200px">
          <GraficaDepartamentales  ref="barChart" :datos="datosDepartamentales" :titulos="titulosDepartamentales" :label="'Departamentales'"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" >
      <v-card elevation="0" min-height="200px">
         <GraficaTotalVotantesVue :datos="[porcentajePromedioTotalDepartamentos,restoPorcentajeTotal]" :titulos="['total de Votantes','Ausentes']" :label="'Departamentales'"/>
        </v-card>
      </v-col>
     
      <v-col cols="12" md="12" >
        <v-card elevation="0" min-height="200px">
          <GraficaDepartamentales  ref="barChart" :datos="datosDepartamentos" :titulos="titulosDepartamentos" :label="'Departamentos'"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import GraficaDepartamentales from "@/components/graficas/GraficaDepartamentales.vue"
import GraficaTotalVotantesVue from "@/components/graficas/GraficaTotalVotantes.vue";
import io from 'socket.io-client';
const socket = io(process.env.VUE_APP_BACKEND_ENVIRONMENT_SOCKET);
import axios from 'axios';




export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    GraficaDepartamentales,
    GraficaTotalVotantesVue
      
  },
  beforeCreate() {
    socket.on('actualizarTabla', (data) => {
      console.log(data);
      this.updateObject(data)
     
    });
  },
  async created() {
    // Obtener los datos iniciales
    await this.updateData();
  },
  data() {
    return {
      restoPorcentajeTotal:null,
      porcentajePromedioTotalDepartamentos:null,
      titulosDepartamentos:[],
      datosDepartamentos:[],
      titulosDepartamentales: [],
      datosDepartamentales: [],
      tableData: [],
      search:'',

      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Graficas ",
          disabled: false,
          link: false,
        },
      ],
    };
  },
  computed: {
    table() {
      return this.tableData;
    },
    headers() {
      return [
        
        {
          sortable: true,
          text: 'Escuela',
          value: 'escuela',
        },
        {
          text: 'Departamental',
          value: 'departamental',
        },
        {
          text: 'Urnas',
          value: 'urnas',
        },
        {
          text: 'Porcentaje Votos',
          value: 'porcentaje_votos',
        },
       
      ];
    },

  },
  methods: {
    async updateData() {
      axios.get( `${process.env.VUE_APP_BACKEND_ENVIRONMENT_ELECCIONES}/Estadisticas/vista-escuelas`).then((response) => {
        console.log("respuesta de las estadisticas", response);
        let departamentales = [];
        const departamentos = [];
       
      response.data.resultados.forEach((item) => {
        const { departamento, porcentaje_votos } = item;
        if (!departamentos[departamento]) {
          departamentos[departamento] = { total: 0, cantidad: 0 };
        }
        departamentos[departamento].total += porcentaje_votos;
        departamentos[departamento].cantidad++;
      });

      // Calcular el promedio de votos por departamento y almacenarlo en datosDepartamentales
      this.datosDepartamentos = []
      this.datosDepartamentos= Object.keys(departamentos).map((departamento) => {
        const { total, cantidad } = departamentos[departamento];
        return (total / cantidad).toFixed(2);
      });

      // Calcular el porcentaje total de todos los departamentos
      const porcentajeTotal = this.datosDepartamentos.reduce((acumulador, porcentaje) => acumulador + parseFloat(porcentaje), 0);
    const promedioPorcentajeTotal = (porcentajeTotal / this.datosDepartamentos.length).toFixed(2);
    this.porcentajePromedioTotalDepartamentos = promedioPorcentajeTotal; // Almacena el promedio
     let restoPorcentaje = (100 - promedioPorcentajeTotal)
     this.restoPorcentajeTotal =(restoPorcentaje).toFixed(2);
     console.log('resto',this.restoPorcentajeTotal);
    console.log("Promedio del porcentaje total de departamentos:", this.porcentajePromedioTotalDepartamentos);


     
        //response.data.map((item) => departamentales.push(item.departamental));
        departamentales = response.data.resultados.reduce((acumulador, elemento) => {
          const { departamental, porcentaje_votos } = elemento;
          if (!acumulador[departamental]) {
            acumulador[departamental] = { total: 0, cantidad: 0, promedio: 0 };
          }
          acumulador[departamental].total += porcentaje_votos;
          acumulador[departamental].cantidad++;
          return acumulador;
        }, {});

        this.datosDepartamentales = []
        Object.keys(departamentales).map((clave) => {
          const { total, cantidad } = departamentales[clave];
          departamentales[clave].promedio = (parseInt(total) / parseInt(cantidad)).toFixed(2);
          this.datosDepartamentales.push(departamentales[clave].promedio);
        //  console.log('home--->',this.datosDepartamentales);
        });
        this.titulosDepartamentales = Object.keys(departamentales);
        this.tableData = response.data.resultados;
        this.titulosDepartamentos = Object.keys(departamentos);
      
         console.log("depart",this.titulosDepartamentos);
        //
      });
    },
    async updateObject(newObject) {
      console.log('new object', newObject);
      const indiceObjeto = this.tableData.findIndex((objeto) => objeto.id_escuela === newObject.id_escuela);

      this.$set(this.tableData, indiceObjeto, newObject);
      console.log('indice',indiceObjeto)
      this.tableData[indiceObjeto] = newObject;

      let tabla = this.tableData
      this.tableData = tabla
      //console.log('new object', this.tableData);
      let departamentales = [];
      let departamentos =[]
        //response.data.map((item) => departamentales.push(item.departamental));
        departamentos = this.tableData.reduce((acumulador, elemento) => {
          const { departamento, porcentaje_votos } = elemento;
          if (!acumulador[departamento]) {
            acumulador[departamento] = { total: 0, cantidad: 0, promedio: 0 };
          }
          acumulador[departamento].total += porcentaje_votos;
          acumulador[departamento].cantidad++;
          return acumulador;
        }, {});
        this.datosDepartamentos = []
        Object.keys(departamentos).map((clave) => {
          const { total, cantidad } = departamentos[clave];
          departamentos[clave].promedio = (parseInt(total) / parseInt(cantidad)).toFixed(2);
          this.datosDepartamentos.push(departamentos[clave].promedio);
        });

        departamentales = this.tableData.reduce((acumulador, elemento) => {
          const { departamental, porcentaje_votos } = elemento;
          if (!acumulador[departamental]) {
            acumulador[departamental] = { total: 0, cantidad: 0, promedio: 0 };
          }
          acumulador[departamental].total += porcentaje_votos;
          acumulador[departamental].cantidad++;
          return acumulador;
        }, {});
        this.datosDepartamentales = []
        Object.keys(departamentales).map((clave) => {
          const { total, cantidad } = departamentales[clave];
          departamentales[clave].promedio = (parseInt(total) / parseInt(cantidad)).toFixed(2);
          this.datosDepartamentales.push(departamentales[clave].promedio);
        });
        console.log('tabla completa', this.tableData);
        

    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
 
 }
</script>

<style></style>
