<template>
  <div>
    <TitleDashboard
      :breadcrumbsItems="items"
      :title="'Estado Del Establecimiento'"
    />

    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="pa-5" elevation="0" min-height="200px">
          <v-card-title> Seleccione la escuela </v-card-title>
          <v-card-subtitle>
            Busque en el menu desplegable la escuela editar
          </v-card-subtitle>

          <v-autocomplete
            v-model="escuela"
            :items="escuelas"
            item-text="dataText"
            @change="setearDatosEscuela()"
            item-value="dataValue"
            label="Seleccionar Escuela"
            outlined
          />
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" min-height="200px" v-if="mostrarDataEscuela">
          <v-card-title> Estado General </v-card-title>
          

          

          <v-card-text>
            <v-switch
              v-model="StateSchool"
              :label="
                StateSchool ? 'Escuela Habilitada' : 'Escuela No Habilitada'
              "
            ></v-switch>
            <p>Cantidad de Urnas</p>
            <v-select
              :items="itemUrnas"
              v-model="urnas"
              label="Seleccione cantidad de urnas"
              outlined
            ></v-select>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-text>
            <p>Porcentaje de Votantes</p>
            <v-text-field
              label="Escriba el porcentaje"
              outlined
              type="Number"
              v-model.number="porcentaje"
              :rules="porsentajeRules"
              suffix="%"
            ></v-text-field>
            <v-progress-linear
              @change="updatePorcentaje"
              :color="color"
              v-model="porcentaje"
              height="25"
            >
              <strong>{{ Math.ceil(porcentaje) }}%</strong>
            </v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              block
              @click="sendData()"
              :disabled="porcentaje > 100"
              :loading="loderButton"
              >enviar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import { mapState } from "vuex";

import Vue from "vue";

export default {
  name: "EdicionPorEscuela",
  components: {
    TitleDashboard,
  },
  async created() {
    //  await this.getStateSchool()
    await this.$store.dispatch("elecciones/getEscuelas");
  },
  data() {
    return {
      mostrarDataEscuela: false,
      escuela: null,
      porsentajeRules: [
        (v) => v <= 100 || "Error el porcentaje no puede superar el 100%",
      ],
      loderButton: false,
      StateSchool: false,
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Estado de Escuela ",
          disabled: false,
          link: false,
        },
      ],
      porcentaje: 0,
      urnas: null,
      itemUrnas: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
    };
  },

  computed: {
    ...mapState("elecciones", ["escuelas"]),

    color() {
      const colorMapping = {
        "0-20": "red darken-1",
        "21-50": "yellow lighten-2",
        "51-80": "light-blue accent-3",
        "81-100": "light-green accent-4",
      };

      const range = Object.keys(colorMapping).find((range) => {
        const [min, max] = range.split("-");
        return this.porcentaje >= Number(min) && this.porcentaje <= Number(max);
      });

      return colorMapping[range];
    },
    nombreEscuela() {
      let nombre = localStorage.getItem("nombreEscuela") || "Sin Datos";
      return nombre;
    },
  },

  methods: {
    async setearDatosEscuela() {
      console.log("id de la escuela seleccionada", this.escuela);
      try {
        let url = "/escuelas/novedad/estado";

        let params = { id_escuela: this.escuela };

        const r = await Vue.prototype.$rest.get({ url, params });
        console.log(r.data.escuela);
        this.mostrarDataEscuela = true;
        this.StateSchool = r.data.escuela.habilitada || false;
        this.urnas = r.data.escuela.urnas || 0;
        this.porcentaje = r.data.escuela.porcentaje_votos || 0;
      } catch (err) {
        console.log(err);
      } finally {
      }
    },
    updatePorcentaje(value) {
      console.log(value);
      this.porcentaje = Math.round(value);
    },
    async getStateSchool() {
      const id_escuela = localStorage.getItem("escuela");
      if (!id_escuela || id_escuela === "null") {
        Vue.prototype.$toast.open({
          message:
            "Para registrar informacion, primero es necesario darse entrada al servicio ",
          position: "top-right",
          type: "error",
          duration: 3000,
        });

        return;
      }
      try {
        let url = "/escuelas/novedad/estado";

        let params = { id_escuela: id_escuela };

        const r = await Vue.prototype.$rest.get({ url, params });
        console.log(r.data.escuela);
        this.StateSchool = r.data.escuela.habilitada || false;
        this.urnas = r.data.escuela.urnas || 0;
        this.porcentaje = r.data.escuela.porcentaje_votos || 0;
      } catch (err) {
        console.log(err);
      } finally {
      }
    },
    async sendData() {
      this.loderButton = true;

      const id_escuela = this.escuela;
      const userData = JSON.parse(localStorage.getItem("userData"));
      const id_policia = userData?.id_policia;
      const urnas = this.urnas || 0;
      const porcentaje_votos = parseInt(this.porcentaje);
      const habilitada = this.StateSchool;

      if (!this.escuela || this.escuela === "null" || !id_policia) {
        Vue.prototype.$toast.open({
          message:
            "Error al registrar informacion ",
          position: "top-right",
          type: "error",
          duration: 3000,
        });

        this.loderButton = false;
        return;
      }

      try {
        const params = {
          url: "/escuelas/novedad",
          formData: {
            id_escuela,
            id_policia,
            urnas,
            porcentaje_votos,
            habilitada,
          },
        };
        console.log(params);
        await Vue.prototype.$rest.post(params);
      } catch (err) {
        console.log(err);
      } finally {
        this.loderButton = false;
      }
    },
  },
};
</script>

<style></style>
