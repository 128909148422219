<template>
  <div>
    <v-card
      color="red darken-1"
      dark
      elevation="3"
      class="rounded-lg"
      style="overflow: hidden"
    >
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title>
            Deshabilitar <br />
            Porsentaje
          </v-card-title>

          <v-card-actions>
            <v-btn outlined rounded small block @click="dialog = !dialog">
              confirmar
            </v-btn>
          </v-card-actions>
        </div>

        <v-avatar class="ma-3" size="110">
          <v-icon class="mr-1" color="white" size="110">mdi-alert</v-icon>
        </v-avatar>
      </div>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Confirmar la Accion </v-card-title>
        <v-card-text>Este evento produce cambios permanentes</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false"> cancelar </v-btn>
          <v-btn
            color="red darken-1"
            dark
            :loading="loderButton"
            @click="sendData()"
          >
            confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "CardDeshabilitarPorsentaje",
  props: {
    ruta: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loderButton: false,
      dialog: false,
      items: [
        {
          color: "#64B5F6",
        },
      ],
    };
  },
  methods: {
    async sendData() {
      this.loderButton = true;

      const userData = JSON.parse(localStorage.getItem("userData"));
      const dni = userData?.dni;

      try {
        const params = {
          url: "/escuelas/novedades/bloquear",
          formData: {
            dni,
          },
        };
        console.log(params);
        await Vue.prototype.$rest.post(params);
      } catch (err) {
        console.log(err);
      } finally {
        this.loderButton = false;
        this.dialog = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
