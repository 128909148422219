import { render, staticRenderFns } from "./PanelInformativo.vue?vue&type=template&id=85ed0b58&"
import script from "./PanelInformativo.vue?vue&type=script&lang=js&"
export * from "./PanelInformativo.vue?vue&type=script&lang=js&"
import style0 from "./PanelInformativo.vue?vue&type=style&index=0&id=85ed0b58&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports