<template>
  <div>
    <TitleDashboard
      :breadcrumbsItems="items"
      :title="'Ingresos al establecimiento'"
    />

    <v-row class="mt-4">
      <!-- <v-col cols="12" md="8">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Seleccionar Escuela
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Entrada al Servicio
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Fin del Servicio </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" elevation="0" min-height="200px">
                <v-card-title> Seleccione la escuela </v-card-title>
                <v-card-subtitle>
                  Busque en el menu desplegable su escuela y luego pulse el
                  boton continuar
                </v-card-subtitle>

                <v-autocomplete
                  v-model="escuela"
                  :items="escuelas"
                  item-text="dataText"
                  item-value="dataValue"
                  label="Seleccionar Escuela"
                  outlined
                />
              </v-card>

              <v-btn color="primary" :disabled="!escuela" @click="e1 = 2">
                Continuar
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card elevation="0" min-height="200px">
                <v-card-title class="pb-0"> Resumen de Ingreso </v-card-title>
                <v-card-text class="mb-0 pb-0 pt-0 font-weight-bold"> Escuela {{mostrarEscuela()}} </v-card-text>
                
                <v-card-subtitle>
                  Precione el boton "ENTRADA" para marcar su ingreso al
                  servicio
                </v-card-subtitle>

                <v-card-text
                  v-for="(item, i) in policeList"
                  :key="i"
                  class="pb-2"
                >
                  <div
                    class="d-flex justify-space-between align-center"
                    width="100%"
                  >
                    <p class="ma-0">
                      {{ item.value.jerarquia + " " + item.value.nombre }}
                      <br />
                      {{ item.value.destino }}
                    </p>
                  </div>
                  <v-divider class="mt-2"></v-divider>
                </v-card-text>
              </v-card>

              <v-btn text outlined class="mx-2" @click="e1 = 1"> Atras </v-btn>
              <v-btn
                color="primary"
                @click="sendPoliceList()"
                :loading="loderButton"
              >
                entrada
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card elevation="0" min-height="200px">
                <v-card-title> Marcar Salida </v-card-title>
                <v-card-text
                  v-for="(item, i) in policeList"
                  :key="i"
                  class="pb-2"
                >
                  <div
                    class="d-flex justify-space-between align-center"
                    width="100%"
                  >
                    <p class="ma-0">
                      {{ item.value.jerarquia + " " + item.value.nombre }}
                      <br />
                      {{ item.value.destino }}
                    </p>
                  </div>
                  <v-divider class="mt-2"></v-divider>
                </v-card-text>
                <v-card-subtitle>
                  Una vez finalizado el servicio presione el boton "Salida" para
                  marcar su retiro <br />
                  <strong
                    >Recuede que si ud es Encargado de Escuela debe completar el
                    estado de la misma en la seccion Estado de Escuela que se
                    encuentra en el menu izquierdo</strong
                  >
                </v-card-subtitle>
              </v-card>

              <v-btn
                color="red darken-1"
                @click.stop="confirmOut = true"
                dark
                block
              >
                salida
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          color="#26c6da"
          elevation="24"
          centered
        >
          <v-list-item class="grow">
            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar color="white" size="50">
              <v-img src="@/assets/escudo-png.webp" contain></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-snackbar>
      </v-col> -->
      <v-col cols="12" md="4">
        <CardAsistencia />
      </v-col>
    </v-row>
    <!-- !dialog de cofirmacion de salida -->
    <v-dialog v-model="confirmOut" width="500">
      <v-card class="mx-auto" color="#26c6da" dark>
        <v-card-title>
          <v-icon large left> mdi-alert-circle-outline </v-icon>
          <span class="text-h6 font-weight-light">Atención</span>
        </v-card-title>

        <v-card-text class="text-h5 font-weight-bold">
          Para finalizar el servicio presione el boton "confirmar salida"
        </v-card-text>
        <v-card-actions>
          <v-list-item class="grow">
            <v-row align="center" justify="space-around">
              <v-btn color="grey" small @click.stop="confirmOut = false">
                Cancelar
              </v-btn>

              <v-btn
                color="error"
                small
                @click="sendPoliceList(true)"
                :loading="loderButton"
              >
                confirmar salida
              </v-btn>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import CardAsistencia from "@/components/Cards/CardAsistencia.vue";

import { mapState } from "vuex";
import Vue from "vue";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    CardAsistencia,
  },

  data() {
    return {
      snackbar: false,
      text: "Muchas Gracias Por Su Servicio",
      timeout: 3000,
      escuela: null,
      loderButton: false,
      confirmOut: false,

      e1: 1,

      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Ingresos ",
          disabled: false,
          link: false,
        },
      ],
    };
  },
  async created() {
    this.validCompleteForm();
    await this.$store.dispatch("elecciones/getEscuelas");
  },
  methods: {
    mostrarEscuela(){
      return localStorage.getItem("nombreEscuela")
    },
    async validCompleteForm() {
      if (localStorage.getItem("step"))
        this.e1 = parseInt(localStorage.getItem("step"));

      const payload = {
        dni: this.userData.dni,
      };
      await this.$store.dispatch("elecciones/getPolice", payload);
    },
    async sendPoliceList(endService) {
      this.loderButton = true;
      //contruye la lista a enviar
      const sendPoliceList = this.policeList.map((police) => ({
        id_policia: police.value.id_policia,
      }));
      const sendData = {
        asistencias: sendPoliceList,
        id_escuela: this.escuela,
      };

      try {
        const params = {
          url: "/indice/asistencia",
          formData: sendData,
        };

        await Vue.prototype.$rest.post(params);

        if (endService) {
          this.snackbar = true;
          this.e1 = 1;
          this.escuela = null;
          localStorage.removeItem("escuela");
          localStorage.removeItem("nombreEscuela");
          this.confirmOut = false;
        } else {
          this.e1 = 3;
        }
      } catch (err) {
        console.log(err);
        this.loderButton = false;
      } finally {
        this.loderButton = false;
      }
    },
  },
  computed: {
    ...mapState("elecciones", ["escuelas", "policeList"]),
    ...mapState("user", ["userData"]),
  },
  watch: {
    escuela(v) {
      localStorage.setItem("escuela", JSON.stringify(v));

      let  nombreEscuela =this.escuelas.find(escuela => escuela.dataValue == v)
      if(nombreEscuela){
        localStorage.setItem("nombreEscuela", JSON.stringify(nombreEscuela.dataText));
      }
    },
    e1(v) {
      localStorage.setItem("step", JSON.stringify(v));
    },
  },
};
</script>

<style></style>
