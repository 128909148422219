<template>
  <div>
    <TitleDashboard
      :breadcrumbsItems="items"
      :title="'Ingresos al establecimiento'"
    />

    <v-row class="mt-4">
      <v-col cols="12" md="8">
        <v-card elevation="0" min-height="200px" v-if="stateComplete">
          <v-card-title> Nueva Entrada </v-card-title>
          <v-card-subtitle>
            Ud ya completo el ingreso al establecimiento, en esta seccion solo
            podra dar presente a nuevos efectivos
          </v-card-subtitle>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="mx-2"
              :width="this.$vuetify.breakpoint.mobile ? '70%' : '30%'"
              dark
              color="success"
              @click.stop="(dialog = true), (handleFocus = true)"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text v-for="(item, i) in policeList" :key="i" class="pb-2">
            <div class="d-flex justify-space-between align-center" width="100%">
              <p class="ma-0">
                {{ item.value.jerarquia + " " + item.value.nombre }}
                <br />
                {{ item.value.destino }}
              </p>

              <v-btn
                color="red"
                class="ml-2"
                elevation="2"
                icon
                outlined
                text
                @click="deleteItemList(item.value.id_policia)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
            <v-divider class="mt-2"></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="sendPoliceList()"
              :disabled="isContinueDisabled"
              :loading="loderButton"
            >
              enviar
              <v-icon right dark>mdi-send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-stepper v-model="e1" v-else>
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Seleccionar Escuela
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Entrada a Personal Policial
            </v-stepper-step>

            

           
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" elevation="0" min-height="200px">
                <v-card-title> Seleccione la escuela </v-card-title>
                <v-card-subtitle>
                  Busque en el menu desplegable su escuela y luego pulse el
                  boton continuar
                </v-card-subtitle>

                <v-autocomplete
                  v-model="escuela"
                  :items="escuelas"
                  item-text="dataText"
                  item-value="dataValue"
                  label="Seleccionar Escuela"
                  outlined
                />
              </v-card>

              <v-btn color="primary" :disabled="!escuela" @click="e1 = 2">
                Continuar
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card elevation="0" min-height="200px">
          <v-card-title> Nueva Entrada </v-card-title>
          <v-card-subtitle>
            Ingrese el DNI para dar ingreso al efectivo
          </v-card-subtitle>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="mx-2"
              :width="this.$vuetify.breakpoint.mobile ? '70%' : '30%'"
              dark
              color="success"
              @click.stop="(dialog = true), (handleFocus = true)"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text v-for="(item, i) in policeList" :key="i" class="pb-2">
            <div class="d-flex justify-space-between align-center" width="100%">
              <p class="ma-0">
                {{ item.value.jerarquia + " " + item.value.nombre }}
                <br />
                {{ item.value.destino }}
              </p>

              <v-btn
                color="red"
                class="ml-2"
                elevation="2"
                icon
                outlined
                text
                @click="deleteItemList(item.value.id_policia)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
            <v-divider class="mt-2"></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="sendPoliceList()"
              :disabled="isContinueDisabled"
              :loading="loderButton"
            >
              enviar
              <v-icon right dark>mdi-send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

              
            </v-stepper-content>

            
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col cols="12" md="4">
        <CardAsistencia />
      </v-col>
    </v-row>
    <DialogDNI
      :openDialog="dialog"
      :handleFocus="handleFocus"
      @closeDialogDNI="(i) => (dialog = i)"
      @closeFocus="(i) => (handleFocus = i)"
    />
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import CardAsistencia from "@/components/Cards/CardAsistencia.vue";
import DialogDNI from "@/components/Cards/DialogDNI.vue";
import { mapState } from "vuex";
import Vue from "vue";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    CardAsistencia,
    DialogDNI,
  },

  data() {
    return {
      stateComplete: false,
      handleFocus: false,
      dialog: false,
      escuela: null,
      loderButton: false,
      loderSendButton: false,
      e1: 1,
      itemUrnas: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      dataform: {
        StateSchool: false,
        urnas: null,
      },

      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Ingresos ",
          disabled: false,
          link: false,
        },
      ],
    };
  },
  async created() {
    // this.validCompleteForm();
    await this.$store.dispatch("elecciones/getEscuelas");

  },
  methods: {
    async validCompleteForm() {
      if (localStorage.getItem("finallyForm")) {
        this.stateComplete = true;
      } else {
        const payload = {
          dni: this.userData.dni,
        };
        await this.$store.dispatch("elecciones/getPolice", payload);
      }
    },
    async sendPoliceList() {
      this.loderButton = true;
      
       console.log("<",this.policeList);

      const id_policia = this.policeList[0].value.id_policia

        
      
      let sendData = {
        id_policia:id_policia,
        id_escuela: this.escuela,
      };
       console.log(">>>>>>ds",sendData);
      try {
        const params = {
          url: "/indice/asistencia",
          formData: sendData,
        };

        await Vue.prototype.$rest.post(params);
        this.loderButton = false;
        sendData = {}
        console.log('aca esta');
        this.escuela = null
        this.e1 = 1;
        this.$store.dispatch("elecciones/getPolice", null);
        
        
      } catch (err) {
        console.log(err);
        this.loderButton = false;
        
      } finally {
        sendData = {}
        this.escuela = null
        this.e1 = 1;
        this.$store.dispatch("elecciones/getPolice", null);
        this.loderButton = false;
      }
    },
    async sendSchool() {
      this.loderSendButton = true;
      const sendData = {
        id_escuela: localStorage.getItem("escuela"),
        id_policia: this.userData.id_policia,
        habilitada: this.dataform.StateSchool,
        urnas: this.dataform.urnas,
      };

      try {
        const params = {
          url: "/escuelas/novedad",
          formData: sendData,
        };

        await Vue.prototype.$rest.post(params);
        this.stateComplete = true;
      } catch (err) {
        console.log(err);
      } finally {
        this.loderSendButton = false;
        this.stateComplete = true;
        localStorage.setItem("finallyForm", JSON.stringify(true));
      }
    },
    deleteItemList(id) {
      this.$store.dispatch("elecciones/deletePolice", id);
    },
  },
  computed: {
    ...mapState("elecciones", ["escuelas", "policeList"]),
    ...mapState("user", ["userData"]),
    isContinueDisabled() {
      return this.policeList.length === 0;
    },
  },
  watch: {
    escuela(v) {
      localStorage.setItem("escuela", JSON.stringify(v));
    },
  },
};
</script>

<style></style>
