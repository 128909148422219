<template>
  <div>
    <TitleDashboard :breadcrumbsItems="items" :title="'Panel Informativo'" />

    <v-row class="mt-4">
      <v-col cols="12" md="4">
        <v-card
          elevation="2"
          class="d-flex justify-space-between align-center rounded-lg overflow-hidden"
        >
          <v-card-text class="text-h5">Policias Presentes</v-card-text>

          <v-card-text class="text-h3 font-weight-bold" style="z-index: 1">{{
            totalPolicias
          }}</v-card-text>

          <div>
            <div class="bubble-shape-sm-secondary bubble-secondary"></div>
            <v-avatar class="ma-3" size="100" tile>
              <v-icon size="100">mdi-account-check</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card
          elevation="2"
          class="d-flex justify-space-between align-center rounded-lg overflow-hidden"
        >
          <v-card-text class="text-h5"> Escuelas Habilitadas </v-card-text>

          <v-card-text class="text-h3 font-weight-bold" style="z-index: 1">
            {{ totalEscuelasHabilitadas }}
          </v-card-text>

          <div>
            <div class="bubble-shape-sm-primary bubble-primary"></div>
            <v-avatar class="ma-3" size="100" tile>
              <v-icon size="100">mdi-town-hall</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card
          elevation="2"
          class="d-flex justify-space-between align-center rounded-lg overflow-hidden"
        >
          <v-card-text class="text-h5"> Total de Urnas </v-card-text>

          <v-card-text class="text-h3 font-weight-bold" style="z-index: 1">{{
            totalUrnas
          }}</v-card-text>

          <div>
            <div class="bubble-shape-sm-secondary bubble-secondary"></div>
            <v-avatar class="ma-3" size="100" tile>
              <v-icon size="100">mdi-archive-check</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import GraficaDepartamentales from "@/components/graficas/GraficaDepartamentales.vue";
import GraficaTotalVotantesVue from "@/components/graficas/GraficaTotalVotantes.vue";
import io from "socket.io-client";
const socket = io(`${process.env.VUE_APP_BACKEND_ENVIRONMENT_SOCKET}`);
import axios from "axios";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    GraficaDepartamentales,
    GraficaTotalVotantesVue,
  },
  beforeCreate() {
    socket.on("actualizarTabla", (data) => {
      console.log(data);
      this.updateObject(data);
    });
  },
  async created() {
    // Obtener los datos iniciales
    await this.updateData();
  },
  data() {
    return {
      totalUrnas: 0,
      totalEscuelasHabilitadas: 0,
      totalPolicias: 0,
      restoPorcentajeTotal: null,
      porcentajePromedioTotalDepartamentos: null,
      titulosDepartamentos: [],
      datosDepartamentos: [],
      titulosDepartamentales: [],
      datosDepartamentales: [],
      tableData: [],
      search: "",

      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Graficas ",
          disabled: false,
          link: false,
        },
      ],
    };
  },
  computed: {
    table() {
      return this.tableData;
    },
  },
  methods: {
    async updateData() {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT_ELECCIONES}/Estadisticas/vista-escuelas`
        )
        .then((response) => {
          console.log("respuesta", response);

          response.data.resultados.forEach((item) => {
            const { cantidad_policias, habilitada, urnas } = item;
            this.totalPolicias += cantidad_policias;
            this.totalUrnas += urnas;
            if (habilitada === 1) {
              this.totalEscuelasHabilitadas++;
            }
          });

          this.tableData = response.data.resultados;

          //
        })
        .catch((e) => {
          console.log("err", e);
        });
    },
    async updateObject(newObject) {
      console.log("new object", newObject);
      const indiceObjeto = this.tableData.findIndex(
        (objeto) => objeto.id_escuela === newObject.id_escuela
      );
      // Restar la cantidad de policías del objeto anterior y sumar la cantidad del nuevo objeto
      this.totalPolicias -= this.tableData[indiceObjeto].cantidad_policias;
      this.totalPolicias += newObject.cantidad_policias;
      // Restar las urnas del objeto anterior y sumar las urnas del nuevo objeto
      this.totalUrnas -= this.tableData[indiceObjeto].urnas;
      this.totalUrnas += newObject.urnas;
      // Restar 1 si el objeto anterior estaba habilitado y sumar 1 si el nuevo objeto está habilitado
      if (this.tableData[indiceObjeto].habilitada === 1) {
        this.totalEscuelasHabilitadas--;
      }
      if (newObject.habilitada === 1) {
        this.totalEscuelasHabilitadas++;
      }
      this.$set(this.tableData, indiceObjeto, newObject);
      console.log("indice", indiceObjeto);
      this.tableData[indiceObjeto] = newObject;

      let tabla = this.tableData;
      this.tableData = tabla;
      //console.log('new object', this.tableData);
    },
  },
};
</script>

<style>
.bubble-shape-sm-primary.bubble-primary:before {
  background: linear-gradient(
    98deg,
    rgb(8, 230, 174) -14.02%,
    rgba(144, 202, 249, 0) 77.58%
  );
}
.bubble-shape-sm-primary.bubble-primary:after {
  background: linear-gradient(
    393deg,
    rgb(104, 241, 92) -50.94%,
    rgba(144, 202, 249, 0) 83.49%
  );
}

.bubble-shape-sm-secondary.bubble-secondary:before {
  background: linear-gradient(
    98deg,
    rgb(41, 121, 226) -14.02%,
    rgba(144, 202, 249, 0) 77.58%
  );
}
.bubble-shape-sm-secondary.bubble-secondary:after {
  background: linear-gradient(
    393deg,
    rgb(22, 142, 212) -50.94%,
    rgba(144, 202, 249, 0) 83.49%
  );
}

.bubble-shape-sm-secondary:before {
  content: "";
  position: absolute;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  top: -160px;
  right: -30px;
}
.bubble-shape-sm-secondary:after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  top: -57px;
  right: -95px;
}

.bubble-shape-sm-primary:before {
  content: "";
  position: absolute;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  top: -160px;
  right: -30px;
}
.bubble-shape-sm-primary:after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  top: -57px;
  right: -95px;
}
</style>
