<template>
    <div>
      <Pie :data="chartData" :options="chartOptions" />
    </div>
  </template>
  
  <script>
  
import { Pie } from 'vue-chartjs'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  import ChartPlugin from '../../plugins/chartPlugin.js';
  ChartJS.register(ArcElement, Tooltip, Legend, ChartPlugin);
  
  
  export default {
    name: 'GraficaTotalVotantes',
    components: { Pie },
    props: {
      titulos: {
        type: Array,
        default: [],
      },
      datos: {
        type: Array,
        default: [],
      },
      label:{
        type: String,
        default: ' '
      }
    },
    data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        
      },
    };
  },
   
    computed: {
      chartData(){
      
      let nombres = this.titulos
      let datos = this.datos
      
      return {
      labels: nombres,
      datasets: [
        {
          
          backgroundColor:  ['#1E88E5', '#B0BEC5'],
         
          data: datos
        },
      ],
    }
    },
    },
    methods: {
      
    },
  };
  </script>
  
  <style lang="scss" scoped></style>