<template>
    <div>
      <Bar ref="barChart" :options="chartOptions" :data="chartData" />
    </div>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs';
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
  import ChartPlugin from '../../plugins/chartPlugin.js';
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartPlugin);
  
  
  export default {
    name: 'GraficaDepartamentales',
    components: { Bar },
    props: {
      titulos: {
        type: Array,
        default: [],
      },
      datos: {
        type: Array,
        default: [],
      },
      label:{
        type: String,
        default: ' '
      }
    },
    data() {
    return {
      chartOptions: {
        responsive: true,
        
      },
    };
  },
   
    computed: {
      chartData(){
      
      let nombres = this.titulos
      let datos = this.datos
      
      return {
      labels: nombres,
      datasets: [
        {
          label: this.label,
          backgroundColor: '#3949AB',
          borderColor: '#3949AB',
          data: datos
        },
      ],
    }
    },
    },
    methods: {
      
    },
  };
  </script>
  
  <style lang="scss" scoped></style>