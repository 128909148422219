// plugins/chartPlugin.js
const percentagePlugin = {
  id: 'percentagePlugin',
  afterDraw: function (chart) {
    const datasets = chart.config.data.datasets;
    const ctx = chart.ctx;
    const fontSize = window.innerWidth >= 768 ? 10 : 0; // Tamaño de fuente 16px en pantallas de escritorio, 5px en dispositivos móviles
    
    ctx.font = `${fontSize}px Arial`;
    ctx.fillStyle = '#ffff';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const total = dataset.data.reduce((acc, value) => acc + value, 0);

      meta.data.forEach((element, index) => {   
        const percentage = dataset.data[index] + '%';

        const textX = element.tooltipPosition().x;
        const textY = element.tooltipPosition().y + 20; // Ajusta la posición vertical del texto según tus necesidades

        ctx.fillText(percentage, textX, textY);
      });
    });
  },
};

// Exportamos el plugin personalizado
export default percentagePlugin;